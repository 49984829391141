
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "buildId": "2d995b7ef565ae71b8d11b142df3825774152b94",
  "nuxt": {
    "buildId": "8fb0d132-11b1-4718-b760-fe46367021a4"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}



export default /*@__PURE__*/ defuFn(inlineConfig)
